import { slidePageTwoClass } from './identifiers';
import disableTabbing from './disableTabbing';
import enableTabbing from './enableTabbing';

export default function transitionToPrevPage({
	$otpField,
	$backButton,
	$slidingContainer,
	$nextButton,
	$pageTwo,
	$pageOne,
}) {
	$otpField.attr('hidden', true);
	$backButton.attr('hidden', true);
	$slidingContainer.removeClass(slidePageTwoClass);
	$nextButton.attr('disabled', false);
	disableTabbing($pageTwo);
	enableTabbing($pageOne);
}
