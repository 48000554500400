import getPage, { LOGIN_PAGES } from './getPage';
import { accountNamePrefilledTextPageOneClass } from './identifiers';

export default function handleEnterOnLoginForm({ $slidingContainer, $nextButton }, e) {
	const currentPage = getPage($slidingContainer);

	if (currentPage === LOGIN_PAGES.FIRST_PAGE) {
		e.preventDefault();

		// All text buttons can be triggered with both `Space` and `Enter`.
		// To keep that consistent, prevent 'Next' button from being clicked when `Enter` is hit on account name text button.
		if (e.target.className.split(' ').includes(accountNamePrefilledTextPageOneClass)) e.target.click();
		else $nextButton.trigger('click');
	} else if (currentPage === LOGIN_PAGES.INTERMEDIATE_PAGE) {
		e.preventDefault();
	}
}
