import getPage, { LOGIN_PAGES } from './getPage';

export default function focusOnFirstIncompleteField({
	$slidingContainer,
	$accountNameInput,
	$usernameInput,
	$otpField,
	$otpInput,
	$passwordInput,
}) {
	const currentPage = getPage($slidingContainer);

	if (currentPage === LOGIN_PAGES.FIRST_PAGE) {
		if ($accountNameInput.val() === '') {
			$accountNameInput.focus();
		} else {
			$usernameInput.focus();
		}
	} else if (currentPage === LOGIN_PAGES.SECOND_PAGE) {
		setTimeout(() => {
			if ($otpField.attr('hidden') !== 'hidden' && $passwordInput.val() !== '') {
				// Specially useful when user has saved passwords.
				$otpInput.focus();
			} else {
				$passwordInput.focus();
			}
		}, 500);
	}
}
