import { slideIntermediateClass, slidePageTwoClass } from './identifiers';

export const LOGIN_PAGES = {
	FIRST_PAGE: 1,
	SECOND_PAGE: 2,
	INTERMEDIATE_PAGE: 0,
};

export default ($slidingContainer) => {
	if ($slidingContainer.hasClass(slideIntermediateClass)) return LOGIN_PAGES.INTERMEDIATE_PAGE;
	if ($slidingContainer.hasClass(slidePageTwoClass)) return LOGIN_PAGES.SECOND_PAGE;
	return LOGIN_PAGES.FIRST_PAGE;
};
