import {
	accountNameInputID,
	slideIntermediateClass,
	slidePageTwoClass,
	errorNoticeHideClass,
} from './identifiers';
import initializeErrorNoticeDefaultState from './initializeErrorNoticeDefaultState';
import urlPrefix from './urlPrefix';
import disableTabbing from './disableTabbing';
import enableTabbing from './enableTabbing';
import focusOnFirstIncompleteField from './focusOnFirstIncompleteField';

export const blankInputErrorText = 'Login ID or Account name cannot be empty.';
export const getURL = accountName => `/${urlPrefix(accountName)}/auth/identity_details`;
export const getData = loginId => ({ user_str: loginId });

export default function attemptTransitionToNextPage($, elements) {
	const {
		$nextButton,
		$errorNotice,
		$errorNoticeContent,
		$slidingContainer,
		$otpField,
		$signingInAsFieldText,
		$accountNamePrefilledTextPageTwo,
		$usernameInput,
		$backButton,
		$pageOne,
		$pageTwo,
	} = elements;
	$nextButton.attr('disabled', true);

	initializeErrorNoticeDefaultState($errorNotice, $errorNoticeContent);

	const accountName = $(`#${accountNameInputID}`).val();
	const url = getURL(accountName);

	const loginId = $usernameInput.val();
	const data = getData(loginId);

	// Validation for required fields
	if (accountName === '' || loginId === '') {
		$errorNoticeContent.text(blankInputErrorText);
		$errorNotice.removeClass(errorNoticeHideClass);
		$nextButton.attr('disabled', false);
		return;
	}

	$slidingContainer.addClass(slideIntermediateClass);

	const success = (result) => {
		if (result.unique_url) window.location = result.unique_url;
		else {
			if (result.display_otp) $otpField.removeAttr('hidden');
			else $otpField.attr('hidden', 'hidden');
			$signingInAsFieldText.text(loginId);
			$accountNamePrefilledTextPageTwo.text(accountName);
			$backButton.removeAttr('hidden');
			$slidingContainer.addClass(slidePageTwoClass).removeClass(slideIntermediateClass);
			disableTabbing($pageOne);
			enableTabbing($pageTwo);
			focusOnFirstIncompleteField(elements);
		}
	};

	const error = (
		/* Args can be uncommented and used later as needed. */
		// jqXHR,
		// textStatus,
		// errorThrown,
	) => {
		$slidingContainer.removeClass(slideIntermediateClass);
		$errorNotice.removeClass(errorNoticeHideClass);
		$nextButton.attr('disabled', false);
	};

	// get identity details
	$.get({
		url,
		data,
		success,
		error,
	});
}
