export const accountNamePrefilledPageOneClass = 'account-name-prefill';
export const accountNamePrefilledTextPageOneClass = 'login__account-name-prefill--page-one';
export const accountNameID = 'account-name-input';
export const usernameInputName = '__username';
export const loginFormID = 'login-form';
export const pageOneClass = 'login__step-one';
export const pageTwoClass = 'login__step-two';
export const nextButtonID = 'next-page-button';
export const backButtonClass = 'login__step-two--back';
export const slidingContainerClass = 'login__steps--slider';
export const otpFieldID = 'otp-field';
export const otpInputID = 'OTP';
export const signingInAsFieldTextClass = 'login__id-prefill';
export const accountNamePrefilledTextPageTwoClass = 'login__account-name-prefill--page-two';
export const notYouButtonClass = 'login__not-you-button';
export const errorNoticeClass = 'js-error-notice';
export const errorNoticeContentClass = 'alert__content';
export const usernameInputID = 'username';
export const accountNameInputID = 'account';
export const errorNoticeHideClass = 'hide';
export const slidePageTwoClass = 'slide-page-two';
export const slideIntermediateClass = 'slide-intermediate';
export const passwordInputID = 'password';
