import {
	accountNamePrefilledPageOneClass,
	accountNameID,
	usernameInputName,
	loginFormID,
	pageOneClass,
	pageTwoClass,
	nextButtonID,
	backButtonClass,
	slidingContainerClass,
	otpFieldID,
	otpInputID,
	signingInAsFieldTextClass,
	accountNamePrefilledTextPageTwoClass,
	notYouButtonClass,
	errorNoticeClass,
	errorNoticeContentClass,
	accountNameInputID,
	usernameInputID,
	attemptTransitionToNextPage,
	disableTabbing,
	handleEnterOnLoginForm,
	showAccountInput,
	transitionToPrevPage,
	focusOnFirstIncompleteField,
	passwordInputID,
} from './lib/login';

const loginSetup = ($) => {
	const elements = {
		$accountNamePrefilledPageOne: $(`.${accountNamePrefilledPageOneClass}`),
		$accountNameField: $(`#${accountNameID}`),
		$loginForm: $(`#${loginFormID}`),
		$pageOne: $(`.${pageOneClass}`),
		$pageTwo: $(`.${pageTwoClass}`),
		$nextButton: $(`#${nextButtonID}`),
		$backButton: $(`.${backButtonClass}`),
		$slidingContainer: $(`.${slidingContainerClass}`),
		$otpField: $(`#${otpFieldID}`),
		$otpInput: $(`#${otpInputID}`),
		$signingInAsFieldText: $(`.${signingInAsFieldTextClass}`),
		$accountNamePrefilledTextPageTwo: $(`.${accountNamePrefilledTextPageTwoClass}`),
		$passwordInput: $(`#${passwordInputID}`),
		$accountNameInput: $(`#${accountNameInputID}`),
		$usernameInput: $(`#${usernameInputID}`),
		$notYouButton: $(`.${notYouButtonClass}`),
		$errorNotice: $(`.${errorNoticeClass}`),
		$errorNoticeContent: $(`.${errorNoticeClass} > .${errorNoticeContentClass}`),
	};
	const el = elements; // Short hand

	el.$accountNamePrefilledPageOne.on('click', () => showAccountInput(el.$accountNamePrefilledPageOne, el.$accountNameField));
	// Show account input when using shift+tab on the username field
	$(`input[name="${usernameInputName}"]`).on('keydown', (e) => {
		if (e.shiftKey && (e.key === 'Tab' || e.which === 9)) { // Shift + Tab
			showAccountInput(el.$accountNamePrefilledPageOne, el.$accountNameField);
		}
	});
	el.$nextButton.on('click', () => attemptTransitionToNextPage($, elements));

	const goToPrevPage = () => {
		transitionToPrevPage(elements);
		focusOnFirstIncompleteField(elements);
	};
	el.$backButton.on('click', goToPrevPage);
	el.$notYouButton.on('click', goToPrevPage);

	el.$accountNamePrefilledTextPageTwo.on('click', () => {
		transitionToPrevPage(elements);
		showAccountInput(el.$accountNamePrefilledPageOne, el.$accountNameField);
	});
	el.$signingInAsFieldText.on('click', () => {
		transitionToPrevPage(elements);
		el.$usernameInput.trigger('focus');
	});

	el.$loginForm.on('keydown', (e) => {
		if (e.key === 'Enter' || e.which === 13) {
			handleEnterOnLoginForm(elements, e);
		}
	});

	disableTabbing(el.$pageTwo);
	focusOnFirstIncompleteField(elements);
};

// Needed to help with testing as `readyState` is always `complete` in jest mocks
//   and `jQuery` calls `loginSetup` only on receiving `readystatechange` event.
if (document.readyState === 'complete') loginSetup(window.jQuery);
else jQuery(loginSetup);
